import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import { LoadingService } from 'src/app/_services';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  isLoading: Subject<boolean> = this.loadingService.isLoading;
  private unsubscribe$ = new Subject<void>();

  constructor(private loadingService: LoadingService, private cdr: ChangeDetectorRef) {}

  ngOnInit() {
    this.isLoading.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.cdr.detectChanges();
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
