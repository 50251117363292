import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    Injectable,
    ChangeDetectorRef,
    AfterViewChecked,
    HostListener, AfterViewInit
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { EventListService, DataService, HomeService } from 'src/app/_services/';
import { DatePipe } from '@angular/common';
import { SlideInOutAnimation } from 'src/app/animations';
import { Subscription } from 'rxjs';
import { HomeHelper } from 'src/app/_helpers/home/home-helper';
import {NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import { HeaderSharedComponent } from '../../shared/components/header/header-shared.component';
import {EditEventModalComponent} from "../edit-event-modal/edit-event-modal.component";
import {CreateEventModalComponent} from "../create-event-modal/create-event-modal.component";

export const MY_FORMATS = {
    parse: {
        dateInput: 'YYYY-MM-DD',
    },
    display: {
        dateInput: 'ddd, MMM DD, Y',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'EEEE, MMM DD, Y',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

    readonly DELIMITER = '-';

    fromModel(value: string | NgbDateStruct | null): NgbDateStruct | null {
        if (typeof value === 'object') {
            return value;
        }
        if (typeof value === 'string' && value.includes(this.DELIMITER)) {
            const date = value.split(this.DELIMITER);
            return {
                day: parseInt(date[2], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[0], 10),
            };
        }
        return null;
    }

    toModel(date: NgbDateStruct | null): string | null {
        return date ? `${date.year}-${('0' + date.month).slice(-2)}-${('0' + date.day).slice(-2)}` : null;
    }
}

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

    parse(value: string): NgbDateStruct | null {
        if (value) {
            let date = value.split(' ');
            return {
                day: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                year: parseInt(date[2], 10)
            };
        }
        return null;
    }

    format(date: NgbDateStruct | null): string {
        var abbrMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        var abbrDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        if (date) {
            let d = new Date(date.year, date.month - 1, date.day);
            return abbrDays[d.getDay()] + ", " + (abbrMonths[d.getMonth()]) + " " + ('0' + date.day).slice(-2) + ", " + d.getFullYear();
        }
        return '';
    }
}


@Component({
    selector: 'app-event-list',
    templateUrl: './event-list.component.html',
    styleUrls: ['./event-list.component.scss'],
    animations: [SlideInOutAnimation],
    providers: [
        { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter }
    ],
})
export class EventListComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit  {
    @ViewChild('datePicker') picker: any;

    eventList: any = [];
    date: any;

    urlDate: string;
    deploymentVersion: string;

    animationState = 'none';

    totalHours: number = 0;

    hoursForCustomer = 0;

    response = {
        calendar: [],
        expendedTime: 0,
        performanceIndicator: 0,
        user: { Id: 0, FullName: '', ChevronName: '', progress: 0, totalHours: 0 }
    };

    subscription = new Subscription();
    source: string;
    currentYear: number;
    dateForPicker: string;
    
    monthNames: string[] = [
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December'
    ];
    startDate: { year: number, month: number, day: number };
    calendarDate: { year: number, month: number };
    eventDay: NgbDateStruct;
    isSmallScreen: boolean;

    constructor(private router: Router, private activatedRoute: ActivatedRoute, private eventListService: EventListService,
        private dataService: DataService, private location: Location, private DatePipe: DatePipe,
        private homeService: HomeService, private homeHelper: HomeHelper, private modalService: NgbModal, private cdr: ChangeDetectorRef) { }

    ngOnInit() {
        if (localStorage.getItem('token') == null) {
            this.router.navigateByUrl('');
        }

        this.deploymentVersion = localStorage.getItem('deploymentVersion');

        this.subscription = this.dataService.currentDate.subscribe(date => {
            this.date = date;
            if (Object.entries(this.date).length !== 0)
                this.setEventList();
        });

        this.urlDate = this.activatedRoute.snapshot.paramMap.get('date');
        this.source = this.activatedRoute.snapshot.paramMap.get('source'); 

        if (Object.entries(this.date).length === 0 && this.date.constructor === Object) {
            this.updateList()
        } else {
            this.setEventList();
            this.date.forEach(element => {
                this.totalHours += element.MinutesSpent;
            });
            this.totalHoursToString();
        }
        this.currentYear = new Date().getFullYear();
        this.dateForPicker = this.urlDate;

        this.updateUserStatistics()

        this.eventDay = this.getTodayDate();
        const [year, month, day] = this.urlDate.split("-").map(num => parseInt(num, 10));
        this.startDate = { year, month, day };
        this.calendarDate = {year: this.startDate.year, month: this.startDate.month};
        
        this.checkScreenSize();
    }
    ngAfterViewInit(): void {
        const today = new Date().getDay()-1;
        const weekdays = document.querySelectorAll('.ngb-dp-weekday');

        if (weekdays.length > 0 && weekdays[today]) {
            weekdays[today].classList.add('today');
        }
    }

    @HostListener('window:resize', [])
    onResize() {
        this.checkScreenSize();
    }

    getTodayDate(): NgbDateStruct {
        const now = this.urlDate.split('T')[0].split('-').map(Number);
        return { year: now[0], month: now[1], day: now[2] };
    }

    markToday(): void {
        const dayCells = document.querySelectorAll('.ngb-dp-day');
        const listDate = new Date();

        dayCells.forEach(cell => {
            const cellDate = cell.getAttribute('aria-label');
            if (cellDate) {
                const parts = cellDate.split(', ');
                const [dayOfWeek] = parts[0].split(' ');
                const [monthName, dayNum] = parts[1].split(' ');  
                const yearNum = parseInt(parts[2], 10);

                const cellDateObj = new Date(`${monthName} ${dayNum}, ${yearNum}`);

                if (cellDateObj.toDateString() === listDate.toDateString()) {
                    cell.classList.add('today'); 
                } else {
                    cell.classList.remove('today');
                }
            }
        });
    }

    ngAfterViewChecked(): void {
        this.markToday();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    backClicked() {
        this.location.back();
    }

    editClick(eventId: string) {
        this.router.navigateByUrl(`/edit-event/${this.urlDate}/${eventId}/${this.source}`);
    }

    copyClick(event: any) {
        this.eventListService.copyEvent(event)
            .subscribe((res: any) => {
                this.dataService.sendDatetime(res.eventList);
                this.totalHours += event.MinutesSpent;

            },
                err => console.log(err));
    }

    deleteClick(event: any) {
        this.eventListService.deleteEvent(event)
            .subscribe((res: any) => {
                this.dataService.sendDatetime(res.eventList);
                this.totalHours -= event.MinutesSpent;
            },
                err => console.error(err));

        this.homeService.updateUserStatistics(new Date(this.urlDate)).subscribe(
            (res: any) => {
                this.response.expendedTime = res.expendedTime;
                this.response.performanceIndicator = res.performanceIndicator;
                this.response.user.totalHours = res.totalUserHours;
                this.response.user.ChevronName = this.homeHelper.hoursToChevron(res.totalUserHours);
                this.response.user.progress = this.homeHelper.getChevronProgress(this.response.user.totalHours, this.response.user.ChevronName);
                this.homeService.updateData(new Date(this.urlDate));
            },
            err => console.log(err)
        );
    }

    addEventClick() {
        this.source = "event-list";
        this.router.navigateByUrl(`/create-event/${this.urlDate}/${this.source}`);
    }

    previousDateArrowClick() {
        let newDate = new Date(this.urlDate);
        this.totalHours = 0;
        newDate.setDate(newDate.getDate() - 1);
        this.urlDate = this.DatePipe.transform(newDate, 'yyyy-MM-dd');
        this.eventListService.populateEventList(this.urlDate).subscribe(
            (res: any) => {
                res.eventList.forEach(element => {
                    this.totalHours += element.MinutesSpent;
                });
                this.dataService.sendDatetime(res.eventList);
                this.router.navigate([`event-list/${this.urlDate}`]);
            });
        //this.animationState = 'right';
        //this.resetAnimationState();

        this.dateForPicker = this.urlDate;
    }

    nextDateArrowClick() {
        let newDate = new Date(this.urlDate);
        this.totalHours = 0;
        newDate.setDate(newDate.getDate() + 1);
        this.urlDate = this.DatePipe.transform(newDate, 'yyyy-MM-dd');
        this.eventListService.populateEventList(this.urlDate).subscribe(
            (res: any) => {
                res.eventList.forEach(element => {
                    this.totalHours += element.MinutesSpent;
                });
                this.dataService.sendDatetime(res.eventList);
                this.router.navigate([`event-list/${this.urlDate}`]);
            });
        this.dateForPicker = this.urlDate;
    }

    onLogout() {
        localStorage.removeItem('token');
        sessionStorage.removeItem('month');
        sessionStorage.removeItem('year');
        this.router.navigate(['/user/login']);
    }

    private setEventList() {
        // mergeEventsWithSameCustomersToArray() - old func.
        // let customerIdArr = [];
        // let tempArr = [];
        //
        // this.date.forEach(element => {
        //     if (customerIdArr.indexOf(element.Project.Customer.Id) < 0)
        //         customerIdArr.push(element.Project.Customer.Id);
        // });
        //
        // for (let customerId of customerIdArr) {
        //     tempArr = this.date.filter(element => element.Project.Customer.Id == customerId);
        //     this.eventList.push(tempArr);
        // }
        //this.addTotalHoursForSingleCustomer();

        this.eventList = this.date;
    }

    private addTotalHoursForSingleCustomer() {
        let totalHoursForCustomer = 0;

        this.eventList.forEach(element => {
            totalHoursForCustomer += element.MinutesSpent;
        });
        if ((totalHoursForCustomer % 60) < 10) {
            this.eventList.newProperty = Math.floor(totalHoursForCustomer / 60) + "h 0" + (totalHoursForCustomer % 60) + " min ";
        } else {
            this.eventList.newProperty = Math.floor(totalHoursForCustomer / 60) + "h " + (totalHoursForCustomer % 60) + " min ";
        }
    }
    
    totalHoursToString() {
        if ((this.totalHours % 60) < 10) {
            return Math.floor(this.totalHours / 60) + "h 0" + (this.totalHours % 60) + " min ";
        } else {
            return Math.floor(this.totalHours / 60) + "h " + (this.totalHours % 60) + " min ";
        }
    }

    changeDay(day: any) {
        this.totalHours = 0;
        this.urlDate = this.DatePipe.transform(new Date(day.year, day.month - 1, day.day), 'yyyy-MM-dd');
        this.eventListService.populateEventList(this.urlDate).subscribe(
            (res: any) => {
                res.eventList.forEach(element => {
                    this.totalHours += element.MinutesSpent;
                });
                this.dataService.sendDatetime(res.eventList);
                this.router.navigate([`event-list/${this.urlDate}`]);
            });
        this.dateForPicker = this.urlDate;
    }

    isWeekend() {
        const dateObj = this.startDate;
        const date = new Date(dateObj.year, dateObj.month - 1, dateObj.day);
        const weekdayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const dayOfWeek = date.getDay();
        return weekdayNames[dayOfWeek];
    }

    editEventClicked(id: any) {
        if(this.isSmallScreen){
            this.editClick(id);
            return;
        }
        const modalRef = this.modalService.open(EditEventModalComponent, { windowClass: 'event-modal' });
        modalRef.componentInstance.id = id;
        modalRef.componentInstance.isEventList = true;
        modalRef.componentInstance.date = new Date(this.urlDate).toISOString().slice(0, 19);

        modalRef.result.then(async (result) => {
            if (result) {
                this.updateList();
                this.updateUserStatistics();
            }
        }).catch((error) => {
            console.log("Modal closed without confirmation or an error occurred.", error);
        })

    }

    createEventClicked() {
        if(this.isSmallScreen) {
            this.addEventClick();
            return;
        }
        const date = new Date(this.urlDate).toISOString().slice(0, 19);
        const modalRef = this.modalService.open(CreateEventModalComponent, { windowClass: 'event-modal' });
        modalRef.componentInstance.date = date;
        modalRef.componentInstance.isEventList = true;
        modalRef.result.then(async (result) => {
            if (result) {
                this.updateList();
                this.updateUserStatistics();
            }
        }).catch((error) => {
            console.log("Modal closed without confirmation or an error occurred.", error);
        });
    }

    updateList() {
        this.eventListService.populateEventList(this.urlDate).subscribe(
            (res: any) => {
                this.date = res.eventList;
                this.setEventList();
                this.totalHours = 0;
                res.eventList.forEach(element => {
                    this.totalHours += element.MinutesSpent;
                });
            },
            err => console.error(err)
        );
    }

    updateUserStatistics() {
        this.homeService.updateUserStatistics(new Date(this.urlDate)).subscribe(
            (res: any) => {
                this.response.expendedTime = res.expendedTime;
                this.response.performanceIndicator = res.performanceIndicator;
                this.response.user.totalHours = res.totalUserHours;
                this.response.user.FullName = res.firstName;
                this.response.user.ChevronName = this.homeHelper.hoursToChevron(res.totalUserHours);
                this.response.user.progress = this.homeHelper.getChevronProgress(this.response.user.totalHours, this.response.user.ChevronName);
                this.homeService.updateData(new Date(this.urlDate));
            },
            err => console.log(err)
        );
    }

    // CALENDAR
    getMonthName(month: number): string {
        return this.monthNames[month - 1];
    }

    addOneMonth(): void {
        let { year, month } = this.calendarDate;
        month++;
        if (month > 12) {
            month = 1;
            year++;
        }
        this.calendarDate = { year, month };
    }

    subtractOneMonth(): void {
        let { year, month } = this.calendarDate;
        month--;
        if (month < 1) {
            month = 12;
            year--;
        }
        this.calendarDate = { year, month };
    }


    checkDateVisibility() {
        const eventDate = this.startDate; 
        const { month: eventMonth, year: eventYear, day: eventDay } = eventDate;

        if (this.startDate.month === eventMonth && this.startDate.year === eventYear) {
            this.startDate.day = eventDay; 
        } else {
            this.startDate.day = undefined;
        }
    }


    onNavigate() {
        this.checkDateVisibility();
    }

    calendarClick(date: any) {
        this.totalHours = 0;
        let newDate = new Date(date.year, date.month - 1, date.day);
        this.urlDate = this.DatePipe.transform(newDate, 'yyyy-MM-dd');
        this.eventListService.populateEventList(this.urlDate).subscribe(
            (res: any) => {
                res.eventList.forEach(element => {
                    this.totalHours += element.MinutesSpent;
                });
                this.dataService.sendDatetime(res.eventList);
                this.router.navigate([`event-list/${this.urlDate}`]);
            });
        this.dateForPicker = this.urlDate;
        this.startDate = date;
        this.calendarDate = this.startDate
    }

    private checkScreenSize() {
        this.isSmallScreen = window.innerWidth <= 960;
    }

    toDay() {
        const today = new Date();
        this.startDate = {
            year: today.getFullYear(),
            month: today.getMonth() + 1,
            day: today.getDate()
        };
        this.calendarDate = this.startDate;
        this.calendarClick( this.startDate);
        this.checkDateVisibility();
    }
}
