import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmationDialogComponent } from 'src/app/_components/confirmation-dialog/confirmation-dialog.component';

@Injectable({ providedIn: 'root' })
export class ConfirmationDialogService {

    constructor(private modalService: NgbModal) { }

    public confirm(options: {
        title: string,
        message: string,
        data?: any,
        selectedDate?: string,
        previousDate?: string,
        btnOkText?: string,
        btnCancelText?: string,
        taskId?: number,
        dialogSize?: 'sm' | 'lg'
    }): Promise<boolean> {
        const {
            title,
            message,
            data = null,
            selectedDate = '',
            previousDate = '',
            btnOkText = 'Copy',
            btnCancelText = 'Cancel',
            taskId = null,
            dialogSize = 'sm'
        } = options;

        const modalRef = this.modalService.open(ConfirmationDialogComponent, { size: dialogSize, centered: true });
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.message = message;
        modalRef.componentInstance.data = data;
        modalRef.componentInstance.selectedDate = selectedDate;
        modalRef.componentInstance.previousDate = previousDate;
        modalRef.componentInstance.btnOkText = btnOkText;
        modalRef.componentInstance.btnCancelText = btnCancelText;
        modalRef.componentInstance.taskId = taskId;

        return modalRef.result;
    }


}
