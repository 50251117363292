import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ConfirmationDialogService } from 'src/app/_services/confirmation-dialog/confirmation-dialog.service';

@Component({
    selector: 'app-event',
    templateUrl: './event.component.html',
    styleUrls: ['./event.component.scss']
})
export class EventComponent implements OnInit {

    @Input() event: any;
    @Input() todayDate: any;
    @Output() onEditClick = new EventEmitter<string>();
    @Output() onDeleteClick = new EventEmitter<string>();

    info_width: number;
    project_width: number;
    isProjectLocked: boolean = false;

    constructor(private confirmationDialogService: ConfirmationDialogService) { }

    ngOnInit() {
        this.isProjectLocked = (this.event.Project.EventAcceptanceDate > this.todayDate) ? true : false;
        this.decode(this.event);
    }

    editClick(eventId: string) {
        this.onEditClick.emit(eventId);
    }

    deleteClick(event: any) {
        this.openConfirmationDialog(event).then(
            (res) => {
                if (res) {
                    this.onDeleteClick.emit(event);
                }
            },
            (err) => console.log(err)
        );
    }

    decode(event: any) {
        event.UserComment = event.UserComment.replace(/\&amp;/g, "&");
    }

    private openConfirmationDialog(event): Promise<boolean> {
        return this.confirmationDialogService.confirm({
            title: 'Please confirm..',
                message: 'Are you sure you want to Delete the Event?',
                data: event,
        })
            .then((confirmed) => confirmed)
            .catch(() => false);
    }
}
