// import { Deserializable } from '../deserializable.model';
import { Customer } from './customer.model';

export class Project {

    constructor(public Id: number, public Name: string, public TotalHours: number, public Customer: Customer, public TaskId: number) {}

    getProjectId() {
        return this.Id;
    }
}