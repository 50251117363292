import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ImportService {

    constructor(private http: HttpClient) { }

    token: string = localStorage.getItem('token');

    readonly BaseUrl = environment.baseUrl;
    headers = {
        headers: new HttpHeaders({
            'Content-Type': 'application/json',
            // tslint:disable-next-line: object-literal-key-quotes
            'Token': this.token
        })
    };

    getCustomers(): Observable<any> {
        return this.http.get<any>(this.BaseUrl + `/Event/GetCustomers`, this.headers);
    }

    getProjectsByCustomerID(customerId: number, date?: any): Observable<any> {
        return this.http.get<any>(this.BaseUrl + `/Event/GetProjectsByCustomerId/${customerId}/${date != null ? date : 'null'}`, this.headers);
    }

    getRecentProject(): Observable<any> {
        return this.http.get<any>(this.BaseUrl + `/Event/GetRecentProjects`, this.headers);
    }

    getLastLockedDate(projectId: number): Observable<any> {
        return this.http.get<any>(this.BaseUrl + `/Event/GetLastProjectLockedDate/${projectId}`, this.headers);
    }

    createEventFromCSV(event: any) {
        return this.http.post(this.BaseUrl + `/Event/CreateEventFromCSV`, event, this.headers);
    }

    validateDatesForGivenProject(dates: any, projectID: number, toDate: any) {
        return this.http.post<any>(this.BaseUrl + `/Event/ValidateDatesForGivenProject/${projectID}/${toDate}`, dates, this.headers);
    }
}
