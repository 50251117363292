import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatProgressBarModule } from '@angular/material';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ToastrModule } from 'ngx-toastr';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material';
import { TransferHttpCacheModule } from '@nguniversal/common';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { UserService, LoadingService } from './_services';
import { ConfirmationDialogService } from './_services/confirmation-dialog/confirmation-dialog.service';
import { AuthInterceptor } from './auth/auth.interceptor';

import {
    UserComponent, LoginComponent, ResetPassComponent, ForgotPassComponent,
    HomeComponent, DayComponent, EventListComponent, EventComponent, EditEventComponent,
    CustomersComponent, ProjectsComponent
} from './_components';
import { TimeSpentComponent } from './_components/edit-event/time-spent/time-spent.component';
import { CreateEventComponent } from './_components/create-event/create-event.component';
import { RecentProjectsComponent } from './_components/edit-event/recent-projects/recent-projects.component';
import { LoadingComponent } from './_components/loading/loading.component';
import { LoadingInterceptor } from './loading/loading.interceptor';
import { ConfirmationDialogComponent } from './_components/confirmation-dialog/confirmation-dialog.component';
import { ChevronDialogComponent } from './_components/home/chevron/chevron-dialog.component';
import { DatePipe } from '@angular/common';
import { MobileViewComponent } from './_components/mobile-view/mobile-view.component';
import { AppAutoFocusDirective } from './_directive/app-auto-focus.directive';
import { CookieService } from 'ngx-cookie-service';
import { ImportDialogComponent } from './_components/home/import-dialog/import-dialog.component';
import { VacationDialogComponent } from './_components/home/vacation/vacation-dialog.component';
import { PapaParseModule } from 'ngx-papaparse';
import { SharedModule } from './shared/shared.module';
import {EditEventModalComponent} from "./_components/edit-event-modal/edit-event-modal.component";
import {CreateEventModalComponent} from "./_components/create-event-modal/create-event-modal.component";
import { NumberWithSpacesPipe } from './number-with-spaces.pipe';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ForgotPassComponent,
        UserComponent,
        HomeComponent,
        ResetPassComponent,
        DayComponent,
        EventListComponent,
        EventComponent,
        EditEventComponent,
        CustomersComponent,
        ProjectsComponent,
        TimeSpentComponent,
        CreateEventComponent,
        RecentProjectsComponent,
        LoadingComponent,
        ConfirmationDialogComponent,
        ChevronDialogComponent,
        MobileViewComponent,
        AppAutoFocusDirective,
        ImportDialogComponent,
        VacationDialogComponent,
        EditEventModalComponent,
        CreateEventModalComponent,
        NumberWithSpacesPipe
    ],
    imports: [
        BrowserModule,
        NgbModule,
        MatProgressBarModule,
        DragDropModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatMenuModule,
        MatDialogModule,
        MatInputModule,
        PapaParseModule,
        ToastrModule.forRoot({
            progressBar: true
        }),
        SharedModule,
        TransferHttpCacheModule
    ],
    providers: [UserService, LoadingService, ConfirmationDialogService, DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingInterceptor,
            multi: true,
        }, CookieService],
    bootstrap: [AppComponent],
    entryComponents: [ConfirmationDialogComponent, ChevronDialogComponent, ImportDialogComponent, VacationDialogComponent, EditEventModalComponent, CreateEventModalComponent]
})
export class AppModule { }
