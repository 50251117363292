import { Component } from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {PlatformLocation} from "@angular/common";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'client';
  constructor(
      private modalService: NgbModal,
      private platformLocation: PlatformLocation
  ) {
    platformLocation.onPopState(() => this.modalService.dismissAll());
  }
}
