import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    ElementRef,
    ChangeDetectorRef
} from '@angular/core';
import { EditEventService, DataService } from 'src/app/_services';

@Component({
    selector: 'app-customers',
    templateUrl: './customers.component.html',
    styleUrls: ['./customers.component.scss']
})
export class CustomersComponent {

    @Input() currentCustomerId: number;
    @Input() selectedDate: string;
    @Input() disabled: boolean = false;
    @Input() disabledCustomerName: string;
    @Input() isModal?: boolean = false;
    @Output() onCustomerIdSelected = new EventEmitter<number>();
    @Output() onCustomersDownloadFinished = new EventEmitter();
    @Output() onCheckCustomerAvailability = new EventEmitter();
    
    eventCustomers: any;

    searchTerm: string = '';
    filteredCustomers = this.eventCustomers;
    @ViewChild('searchInput') searchInput!: ElementRef;

    constructor(private editEventService: EditEventService, private dataService: DataService, private _cdRef: ChangeDetectorRef) { }
    
    filterCustomers() {
        if (this.searchTerm) {
            this.filteredCustomers = this.eventCustomers.filter(customer =>
                customer.Name.toLowerCase().includes(this.searchTerm.toLowerCase())
            );
        } else {
            this.filteredCustomers = this.eventCustomers;
        }
    }

    getSelectedCustomerName(): string {
        const selectedCustomer = this.eventCustomers.find(customer => customer.Id === this.currentCustomerId);
        return selectedCustomer ? selectedCustomer.Name : '';
    }

    populateList(date?: string) {
        const populateDate = date && date.trim() ? date : this.selectedDate;
        this.editEventService.populateEventWithCustomers(populateDate).subscribe(
            (res: any) => {
                this.eventCustomers = res.customers;
                let customerAvailable = this.eventCustomers.filter(customer => customer.Id == this.currentCustomerId).length == 1;
                if (customerAvailable) {
                    this.dataService.sendCustomerId(this.currentCustomerId);
                    this.onCustomersDownloadFinished.emit();
                } else {
                    this.onCheckCustomerAvailability.emit();
                }
                this.filteredCustomers = this.eventCustomers;
            },
            err => console.error(err)
        );
    }

    customerClicked(customerId: number) {
        this.currentCustomerId = customerId;
        this.onCustomerIdSelected.emit(customerId);
    }
    
    onToggle(dropDownOpen: boolean) {
        if (dropDownOpen) {
            this._cdRef.detectChanges();
            this.searchInput.nativeElement.focus();
        }
    }
}
