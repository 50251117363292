import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberWithSpaces'
})
export class NumberWithSpacesPipe implements PipeTransform {
  transform(value: number): string {
    if (!value) return '0'; // Возвращаем '0', если значение ноль или undefined
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' '); // Добавляем пробелы
  }
}
