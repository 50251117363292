import {Component, OnInit, Input, Output, EventEmitter, HostListener} from '@angular/core';
import { DatePipe } from '@angular/common';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Project } from '../../../_shared/event/project.model';
import { ConfirmationDialogService } from '../../../_services/confirmation-dialog/confirmation-dialog.service';
import { HomeService } from '../../../_services/home/home.service';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {EditEventModalComponent} from "../../edit-event-modal/edit-event-modal.component";
import {CreateEventModalComponent} from "../../create-event-modal/create-event-modal.component";

@Component({
    selector: 'app-day',
    templateUrl: './day.component.html',
    styleUrls: ['./day.component.scss'],
})
export class DayComponent implements OnInit {

    @Input() cell: any;
    @Input() month: any;
    @Input() connectedLists: any;

    @Output() onDaySelected = new EventEmitter<string>();
    @Output() onCreateEvent = new EventEmitter<string>();
    @Output() onUpdateUserStatistics = new EventEmitter<void>();
    @Output() onUpdateCalendar = new EventEmitter<void>();

    static todaysFullDate: string;
    eventDate: string;

    eventCustomersProjects: Project[] = [];
    isSmallScreen = false;

    constructor(private datepipe: DatePipe, private confirmationDialogService: ConfirmationDialogService, private homeService: HomeService, private modalService: NgbModal) { }

    ngOnInit() {
        if (DayComponent.todaysFullDate == null) {
            DayComponent.todaysFullDate = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
            this.homeService.getTodaysDate().subscribe(
                (res) => DayComponent.todaysFullDate = this.datepipe.transform(res.todaysDate, 'yyyy-MM-dd'));
        }

        this.eventDate = this.cell.EventDate.substring(0, this.cell.EventDate.length - 9);

        if (this.cell.Customers == null) {
            this.cell.Customers = [];
        }

        this.cell.Customers.forEach(customer => {
            customer.Projects.forEach(project => {
                const filteredProjects = customer.Projects.filter(x => x.ProjectID === project.ProjectID);

                if (filteredProjects.length > 0) {
                    project.Tasks.forEach(t => {
                        this.eventCustomersProjects.push(
                            new Project(
                                project.ProjectID,
                                project.ProjectName,
                                project.ProjectTotals,
                                filteredProjects,
                                t.Id
                            )
                        );
                    })
                }
            });
        });

        this.checkScreenSize();
    }

    @HostListener('window:resize', [])
    onResize() {
        this.checkScreenSize();
    }

    filterProjectsByTaskId(taskId: number) {
        return this.eventCustomersProjects.filter(item => item.TaskId === taskId);
    }

    get getTodaysFullDate() {
        return DayComponent.todaysFullDate;
    }

    dayClicked(day: string) {
        let formattedDay = this.formatDate(day);
        this.onDaySelected.emit(formattedDay);
    }

    editEventClicked(id: string, date: string) {
        const modalRef = this.modalService.open(EditEventModalComponent, { windowClass: 'event-modal' });
        modalRef.componentInstance.id = id;
        modalRef.componentInstance.date = date;

        modalRef.result.then(async (result) => {
            if (result) {
                this.updateCalendar();
                this.updateUserStatistics();
            }
        }).catch((error) => {
            console.log("Modal closed without confirmation or an error occurred.", error);
        })
        
    }

    createEventClicked(date: string) {
        const modalRef = this.modalService.open(CreateEventModalComponent, { windowClass: 'event-modal' });
        modalRef.componentInstance.date = date;
        modalRef.result.then(async (result) => {
            if (result) {
                this.updateCalendar();
                this.updateUserStatistics();
            }
        }).catch((error) => {
            console.log("Modal closed without confirmation or an error occurred.", error);
        })

    }

    createClicked(day: string) {
        let formattedDay = this.formatDate(day);
        this.onCreateEvent.emit(formattedDay);
    }

    drop(event: CdkDragDrop<string[]>) {

        if (event.previousContainer.id !== event.container.id) {
            this.openConfirmationDialog(event.item.data[0], event.container.id, event.previousContainer.id, event.item.data[0].TaskId).then(
                async (res) => {
                    if (res) {
                        event.item.data.TotalHours = event.item.data[0].Customer[0].Tasks[0].MinutesSpent;
                        this.cell.Customers.push(Object.assign({}, event.item.data[0].Customer));
                        event.container.data.push(Object.assign({}, event.previousContainer.data[event.previousIndex]));

                        this.cell.DailyTotals += event.item.data[0].Customer[0].Tasks[0].MinutesSpent;
                        await this.updateUserStatistics();
                        await this.updateCalendar();
                    }
                },
                (err) => console.log(err)
            );
        }
    }
    
    private updateUserStatistics() {
        this.onUpdateUserStatistics.emit();
    }
    private updateCalendar() {
        this.onUpdateCalendar.emit();
    }

    private formatDate(date: string) {
        return this.datepipe.transform(date, 'yyyy-MM-dd');
    }

    private isCustomerDuplicate(eventData: any): boolean {

        let result = this.cell.Customers.some(customer => (customer.CustomerID == eventData.Customer.CustomerID));

        return result;
    }

    private isProjectDuplicate(eventData: any): boolean {

        let result = this.eventCustomersProjects.some(project => (project.Id == eventData.Id));

        return result;
    }

    private openConfirmationDialog(data, selectedDate, previousDate, taskId): Promise<boolean> {
        return this.confirmationDialogService.confirm({
            title: 'Please confirm..',
            message: 'Do you want to copy tasks?',
            data: data,
            selectedDate: selectedDate,
            previousDate: previousDate,
            taskId: taskId
        })
            .then((confirmed) => confirmed)
            .catch(() => false);
    }

    isWeekend(dateStr: string): boolean {
        if (!dateStr) return false;

        const date = new Date(dateStr);
        const dayOfWeek = date.getDay();

        return dayOfWeek === 0 || dayOfWeek === 6;
    }

    private checkScreenSize() {
        this.isSmallScreen = window.innerWidth <= 768;
    }

    handleClick(taskId: string, taskDate: string, event: MouseEvent): void {
        if (!this.isSmallScreen) {
            this.editEventClicked(taskId, taskDate);
            event.stopPropagation();
        }
    }

    setCreateEvent(eventDate: string) {
        !this.isSmallScreen ? this.createEventClicked(eventDate) : this.createClicked(eventDate)
    }
}
