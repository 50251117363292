import {
    Component,
    OnDestroy,
    Input,
    Output,
    EventEmitter,
    ChangeDetectorRef,
    ViewChild,
    ElementRef
} from '@angular/core';
import { EditEventService, DataService } from 'src/app/_services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-projects',
    templateUrl: './projects.component.html',
    styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnDestroy {

    @Input() currentProjectId: number;
    @Input() currentCustomerId: number;
    @Input() disabled: boolean = false;
    @Input() disabledProjectName: string;
    @Input() isModal?: boolean = false;
    @Input() selectedDate: string;

    @Output() onProjectIdSelected = new EventEmitter<number>();

    allProjectsForCustomer: any = [];

    subscription = new Subscription();

    searchTerm: string = '';
    filteredProjects = this.allProjectsForCustomer;
    @ViewChild('searchInput') searchInput!: ElementRef;

    constructor(private editEventService: EditEventService, private dataService: DataService, private _cdRef: ChangeDetectorRef) { }

    filterProjects() {
        if (this.searchTerm) {
            this.filteredProjects = this.allProjectsForCustomer.filter(customer =>
                customer.Name.toLowerCase().includes(this.searchTerm.toLowerCase())
            );
        } else {
            this.filteredProjects = this.allProjectsForCustomer;
        }
    }

    getSelectedProjectsName(): string {
        const selectedProject = this.allProjectsForCustomer.find(project => project.Id === this.currentProjectId);
        return selectedProject ? selectedProject.Name
            : '';
    }

    populateList(isCustomerLocked: boolean = false) {
        if (isCustomerLocked) {
            this.filteredProjects = null;
            this.allProjectsForCustomer = []
            this.disabledProjectName = '';
            this.currentCustomerId = null
        } else {
            this.subscription = this.dataService.selectedCustomerId.subscribe(
                (id: any) => {
                    this.currentCustomerId = id;
                    this.editEventService.populateEventWithProjects(this.currentCustomerId, this.selectedDate).subscribe(
                        (res: any) => {
                            this.allProjectsForCustomer = res.projects;
                            this.filteredProjects = this.allProjectsForCustomer;
                            this.validateProjectSelect();
                        },
                        err => console.error(err)
                    );
                },
                err => console.error(err)
            );
        }
    }
    
    validateProjectSelect() {
        const isValid = this.allProjectsForCustomer.find(project => project.Id === this.currentProjectId);
        if(!isValid) this.projectClicked(null)
    }

    projectClicked(projectId: number) {
        this.currentProjectId = projectId;
        this.onProjectIdSelected.emit(projectId);
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    onToggle(dropDownOpen: boolean) {
        if (dropDownOpen) {
            this._cdRef.detectChanges();
            this.searchInput.nativeElement.focus();
        }
    }
}
