﻿import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HomeService } from 'src/app/_services';
import { HomeHelper } from 'src/app/_helpers/home/home-helper';
import { NgStyle } from '@angular/common';


@Component({
    selector: 'app-chevron-dialog',
    templateUrl: './chevron-dialog.component.html',
    styleUrls: ['./chevron-dialog.component.scss']
})
export class ChevronDialogComponent implements OnInit {

    @Input() chevronName: string = '';
    @Input() progress: number = 0;
    @Input() totalHours: number = 0;
    date: any = new Date();
    position: any;
    constructor(private activeModal: NgbActiveModal, private homeService: HomeService, private homeHelper: HomeHelper, ) { }

    ngOnInit() {
        this.loadScript();
    }
    public dismiss() {
        this.activeModal.dismiss();
    }

    public loadScript() {
        var elem = document.getElementById("percent");
        this.position = this.homeHelper.getPercentPos(this.chevronName);
        elem.innerText = this.progress + '%';
        var width = 0;
        var heigth = 137 * (this.position.vertical - 1);
        width = (this.progress * 110 / 100);
        elem.style.marginLeft = 138.66 * (this.position.horizont - 1) + 41 + width + 'px';
        elem.style.marginTop = heigth + 118 + 'px';
    }
    ngOnDestroy(): void {
        // this.eventList.unsubscribe();
    }

    levels = [
        {
            name: 'ROOKIE',
            hours: { min: 0, max: 500 },
            image: '/assets/img/chevrons/SVG/Rookie.svg'
        },
        {
            name: 'INTERN',
            hours: { min: 501, max: 1000 },
            image: '/assets/img/chevrons/SVG/Intern.svg'
        },
        {
            name: 'FIGHTER',
            hours: { min: 1001, max: 2000 },
            image: '/assets/img/chevrons/SVG/Fighter.svg'
        },
        {
            name: 'STORM TROOPER',
            hours: { min: 2001, max: 3000 },
            image: '/assets/img/chevrons/SVG/StormTrooper.svg'
        },
        {
            name: 'COMMANDOS',
            hours: { min: 3001, max: 6000 },
            image: '/assets/img/chevrons/SVG/Commandos.svg'
        },
        {
            name: 'SPECIAL FORCES',
            hours: { min: 6001, max: 10000 },
            image: '/assets/img/chevrons/SVG/SpecialForces.svg'
        },
        {
            name: 'JUNIOR OFFICER',
            hours: { min: 10001, max: 13000 },
            image: '/assets/img/chevrons/SVG/JuniorOfficer.svg'
        },
        {
            name: 'FIELD OFFICER',
            hours: { min: 13001, max: 21000 },
            image: '/assets/img/chevrons/SVG/FieldOfficer.svg'
        },
        {
            name: 'SUPER DEV',
            hours: { min: 21001, max: 30000 },
            image: '/assets/img/chevrons/SVG/SuperDev.svg'
        },
        {
            name: 'IT ARYANS',
            hours: { min: 31001, max: 40000 },
            image: '/assets/img/chevrons/SVG/ItAryans.svg'
        },
        {
            name: 'ATLANT',
            hours: { min: 40001, max: 50000 },
            image: '/assets/img/chevrons/SVG/Atlant.svg'
        },
        {
            name: 'THE CHOSEN ONE',
            hours: { min: 50001, max: 99999999999 },
            image: '/assets/img/chevrons/SVG/TheChosenOne.svg'
        },
    ];


}