import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { TimeSpentService } from 'src/app/_services/event/timespent.service';
import { TimeSpentBase } from 'src/app/_shared/event/timespent-base';

@Component({
    selector: 'app-time-spent',
    templateUrl: './time-spent.component.html',
    styleUrls: ['./time-spent.component.scss']
})
export class TimeSpentComponent implements OnInit {

    @Input() currentTimeSpent: number;
    @Input() isModal?: boolean = false;
    @Input() disabled: boolean = false;
    @Output() onCurrentTimeSpentSelect = new EventEmitter<number>();

    totalHoursArray: TimeSpentBase<any>[] = [];
    totalMinutesArray: TimeSpentBase<any>[] = [];

    hoursSpent: number = 0;
    minutesSpent: number = 0;

    constructor(private timeSpentService: TimeSpentService) { }

    ngOnInit() {
        this.formatCurrentTimeSpent();
        this.totalHoursArray = this.timeSpentService.getHours();
        this.totalMinutesArray = this.timeSpentService.getMinutes();
    }

    getSelectedHourLabel() {
        const selectedHour = this.totalHoursArray.find(hour => hour.value === this.hoursSpent);
        return selectedHour ? selectedHour.label : 'Select hour';
    }

    getSelectedMinuteLabel() {
        const selectedMinute = this.totalMinutesArray.find(minute => minute.value === this.minutesSpent);
        return selectedMinute ? selectedMinute.label : 'Select minute';
    }

    ngOnChanges(changes: SimpleChanges): void {
        try {
            this.formatCurrentTimeSpent();
        }
        catch (e) {
            console.error(e);
        }
    }

    onCurrentTimeSpentClick(value: number, valueFormat: string) {
        if (valueFormat == 'h') {
            this.hoursSpent = value;
        } else if (valueFormat == 'min') {
            this.minutesSpent = value;
        }

        const timeSpent = this.hoursSpent * 60 + this.minutesSpent;

        this.onCurrentTimeSpentSelect.emit(timeSpent);
    }

    formatCurrentTimeSpent() {
        this.hoursSpent = Math.floor(this.currentTimeSpent / 60);
        this.minutesSpent = (this.currentTimeSpent / 60) % 1 * 60;

        if (this.minutesSpent !== 0
            && this.minutesSpent !== 15
            && this.minutesSpent !== 30
            && this.minutesSpent !== 45) {

            this.minutesSpent = 0;
        }
    }
}
