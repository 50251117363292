import {AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ProjectsComponent} from "../edit-event/projects/projects.component";
import {CustomersComponent} from "../edit-event/customers/customers.component";
import {Router} from "@angular/router";
import {DataService, EditEventService, EventListService} from "../../_services";
import {EventValidation} from "../../_validation/event-validation";
import {AutoSelectService} from "../../_services/event/auto-select.service";
import {EventHelper} from "../../_helpers/event/event-helper";
import {DatePipe} from '@angular/common';
import {ConfirmationDialogService} from "../../_services/confirmation-dialog/confirmation-dialog.service";
import {catchError} from "rxjs/operators";
import {of} from "rxjs";

@Component({
  selector: 'app-event-modal',
  templateUrl: './edit-event-modal.component.html',
  styleUrls: ['./edit-event-modal.component.scss']
})

export class EditEventModalComponent implements OnInit, AfterViewChecked, AfterViewInit {
  @ViewChild('descriptionArea') descriptionElement: ElementRef;
  @ViewChild(ProjectsComponent) projects: ProjectsComponent;
  @ViewChild(CustomersComponent) customers: CustomersComponent;
  @Input() id?: string;
  @Input() date?: string;
  @Input() isEventList?: boolean = false;

  event: any = {};
  
  isDescriptionEmpty = false;

  eventTimeSpent: number = 0;
  currentTimeSpent: number = 0;
  currentDescription: string = '';
  currentCustomerName: string = '';
  currentProjectName: string = '';
  deploymentVersion: string = '';
  currentCustomerId: number = 0;
  currentProjectId: number = 0;

  displayHoursSpent = 0;
  displayMinutesSpent = 0;

  onDateTotalMinutes: number = 0;
  displayTotalHoursSpent: number = 0;
  displayTotalMinutesSpent: number = 0;

  previosUserEvent: any;
  source: string;
  currentYear: number;
  startDate: { year: number, month: number, day: number };
  calendarDate: { year: number, month: number };
  today: NgbDateStruct = this.getTodayDate();

  monthNames: string[] = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  constructor(private router: Router, private datePipe: DatePipe,
              private eventListService: EventListService, private editEventService: EditEventService,
              private dataService: DataService, private eventValidation: EventValidation,
              private autoSelectService: AutoSelectService, private eventHelper: EventHelper,
              public activeModal: NgbActiveModal, private confirmationDialogService: ConfirmationDialogService) { }
  async ngOnInit() {
    if (localStorage.getItem('token') == null) {
      this.router.navigateByUrl('');
    }

    this.deploymentVersion = localStorage.getItem('deploymentVersion');
    this.event.Date = this.dateFormatter(this.date);
    this.source = '';

    await this.eventListService.editEvent(this.id).subscribe(
        (res: any) => {
          this.currentCustomerId = res.singleEvent.Project.Customer.Id;
          this.customers.populateList(this.event.Date);
          this.event = res.singleEvent;
          this.eventTimeSpent = res.singleEvent.MinutesSpent;
          this.currentTimeSpent = res.singleEvent.MinutesSpent;
          this.currentDescription = res.singleEvent.UserComment;
          this.decodeDescription();
          this.currentProjectId = res.singleEvent.Project.Id;
        },
        err => console.error(err)
    );

    await this.editEventService.getTotalMinutes(this.event.Date).subscribe(
        (res: any) => {
          if (res.totalMinutes !== null) {
            this.onDateTotalMinutes = res.totalMinutes;
            this.formatTimeSpent();
          }
        },
        err => console.error(err)
    );
    
    this.currentYear = new Date().getFullYear();
    this.setDateFromEvent(this.event.Date);
    this.today = this.getTodayDate();

  }
  
  getTodayDate(): NgbDateStruct {
    const now = new Date();
    return { year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate() };
  }

  markToday(): void {
    const dayCells = document.querySelectorAll('.ngb-dp-day');

    dayCells.forEach(cell => {
      const cellDate = cell.getAttribute('aria-label');
      if (cellDate) {
        if (this.isEventList) {
          cell.classList.add('disabled');
        } else {
          cell.classList.remove('disabled');
        }
        const parts = cellDate.split(', ');
        const [dayOfWeek] = parts[0].split(' '); 
        const monthAndDay = parts[1].split(' ');
        const monthName = monthAndDay[0];
        const dayNum = parseInt(monthAndDay[1], 10);
        const yearNum = parseInt(parts[2]);

        if (dayNum === this.today.day && this.getMonthIndex(monthName) === this.today.month && yearNum === this.today.year) {
          cell.classList.add('today');
        } else {
          cell.classList.remove('today');
        }
      }
    });
  }

  getMonthIndex(monthName: string): number {
    const months = [
      'January', 'February', 'March', 'April',
      'May', 'June', 'July', 'August',
      'September', 'October', 'November', 'December'
    ];
    return months.indexOf(monthName) + 1;
  }

  ngAfterViewChecked(): void {
    this.markToday();
  }
  
  ngAfterViewInit(): void {
    const today = new Date().getDay()-1;
    const weekdays = document.querySelectorAll('.side .ngb-dp-weekday');

    if (weekdays.length > 0 && weekdays[today]) {
      weekdays[today].classList.add('today');
    }
  }
  
  setDateFromEvent(dateString: string) {
    const [year, month, day] = dateString.split('-').map(Number);
    this.startDate = { year, month, day };
    this.calendarDate = {year: this.startDate.year, month: this.startDate.month};
    this.checkDateVisibility();
  }
  
  dateFormatter(date: string) {
    return date.match(/^\d{4}-\d{2}-\d{2}/)[0]
  }

  
  getMonthName(month: number): string {
    return this.monthNames[month - 1];
  }

  getEventDate() {
    return new Date(this.event.Date).toLocaleDateString("en-US", {
      year: "numeric",
      month: "long",
      day: "numeric"
    });
  }
  
  loadProjects() {
    this.projects.populateList();
    this.currentCustomerName = this.event.Project.Customer.Name;
    this.currentProjectName = this.event.Project.Name;
  }
  setCustomerNullId() {
    this.event.Project.Customer.Id = null;
    this.projects.populateList(true);
  }

  selectTimeSpent(timeSpent: number) {
    this.currentTimeSpent = timeSpent;
    this.event.MinutesSpent = timeSpent;
    this.formatTimeSpent();
  }

  formatTimeSpent() {
    this.displayHoursSpent = Math.floor(this.currentTimeSpent / 60);
    this.displayMinutesSpent = Math.floor(this.currentTimeSpent % 60);

    this.displayTotalHoursSpent = Math.floor((this.onDateTotalMinutes - this.eventTimeSpent + this.currentTimeSpent) / 60);
    this.displayTotalMinutesSpent = Math.floor((this.onDateTotalMinutes - this.eventTimeSpent + this.currentTimeSpent) % 60);
  }

  changeDescription(descriptionValue) {
    this.currentDescription = descriptionValue;
    this.event.UserComment = descriptionValue;
  }

  selectCustomerId(customerValue) {
    this.currentCustomerId = customerValue;
    this.dataService.sendCustomerId(customerValue);
    if (customerValue != null) {
      this.autoSelectService.autoSelectDataForCustomer(customerValue, this.event.Date.substring(0, this.event.Date.length - 9)).subscribe(
          (res: any) => {
            if (res.previousEvent != null) {
              this.currentProjectId = res.previousEvent.Project.Id;
              this.selectProjectId(this.currentProjectId);
            }
            else {
              this.currentProjectId = 0;
            }
            this.formatTimeSpent();
          }
      );
    }
  }

  selectProjectId(projectValue) {
    this.currentProjectId = projectValue;
    this.event.Project.Id = projectValue;
  }

  selectRecentProject(data: any) {
    this.currentCustomerId = data.customerId;
    this.dataService.sendCustomerId(data.customerId);

    this.currentProjectId = data.projectId;
    this.event.Project.Id = data.projectId;
  }

  onSaveClick() {
    this.event.Customer = this.event.Project.Customer;
    const { errors, isValid } = this.eventValidation.validateEvent(this.event);

    if (!isValid) {
      this.eventHelper.displayToasterErrorMessages(errors);
    } else {
      const event = {
        Id: this.event.Id,
        Project: { Id: this.currentProjectId },
        User: { Id: this.event.User.Id },
        MinutesSpent: this.currentTimeSpent,
        MinutesEstimate: this.event.MinutesEstimate,
        UserComment: this.currentDescription,
        Date: this.event.Date,
        IsDeleted: this.event.IsDeleted
      };
      this.eventHelper.replaceAndChar(event);

      this.editEventService.updateEvent(event).subscribe(
          (res: any) => {
            if (res)
              this.dataService.sendDatetime(res.eventList);
            this.onCancelClick(true);
          },
          err => console.error(err)
      );
    }
  }

  onCancelClick(result: boolean = false) {
    this.activeModal.close(result);
  }

  checkFocus() {
    if (window.innerWidth > 768) {
      this.invokeFocusOnTextArea();
    }
  }

  public invokeFocusOnTextArea() {
    this.descriptionElement.nativeElement.focus();
  }

  onLogout() {
    localStorage.removeItem('token');
    sessionStorage.removeItem('month');
    sessionStorage.removeItem('year');
    this.router.navigate(['/user/login']);
  }

  decodeDescription() {
    this.currentDescription = this.currentDescription.replace(/\&amp;/g, "&");
  }

  addOneMonth(): { year: number, month: number, day?: number } {
    const { year, month } = this.startDate;
    this.startDate = month === 12
        ? {year: year + 1, month: 1, day: 1}
        : {year, month: month + 1, day: 1};
    this.checkDateVisibility();
    return this.startDate;
  }

  subtractOneMonth(): { year: number, month: number, day?: number } {
    const { year, month } = this.startDate;
    const tempDate = month === 1
        ? { year: year - 1, month: 12, day: 1 }
        : { year, month: month - 1, day: 1 };

    this.startDate = tempDate;
    this.checkDateVisibility();
    return this.startDate;
  }

  checkDateVisibility() {
    const eventDate = new Date(this.event.Date);
    const eventMonth = eventDate.getMonth() + 1;
    const eventYear = eventDate.getFullYear();
    const eventDay = eventDate.getDate();

    if (this.startDate && this.startDate.month === eventMonth && this.startDate.year === eventYear) {
      this.startDate.day = eventDay;
    } else if (this.startDate) {
      this.startDate.day = undefined;
    }
  }

  onNavigate(event: { next: { year: number; month: number } }) {
    this.calendarDate = event.next;
    this.checkDateVisibility();
  }

  formatToDate({ year, month, day }: { year: number, month: number, day: number }): string {
    const formattedMonth = month.toString().padStart(2, '0'); 
    const formattedDay = day.toString().padStart(2, '0');
    return `${year}-${formattedMonth}-${formattedDay}T00:00:00`;
  }

  calendarClick(date: any) {
    if (this.event.IsLocked) return;
    this.event.Date = this.formatToDate(date);
    this.startDate = date;
    this.customers.populateList(this.dateFormatter(this.event.Date));
  }

  confirmationDeleteClick(event: any) {
    this.openConfirmationDialog(event).then(
        (res) => {
          if (res) {
            this.deleteClick(event);
          }
        },
        (err) => console.log(err)
    );
  }

  private openConfirmationDialog(event): Promise<boolean> {
    return this.confirmationDialogService.confirm({
      title: 'Please confirm..',
      message: 'Are you sure you want to Delete the Event?',
      data: event,
    })
        .then((confirmed) => confirmed)
        .catch(() => false);
  }

  deleteClick(event: any) {
    this.eventListService.deleteEvent(event)
        .subscribe((res: any) => {
              this.onCancelClick(true);
            },
            err => console.error(err));
  }
}
