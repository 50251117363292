import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/_services';
import { DatePipe } from '@angular/common';
import { EventHelper } from '../../_helpers/event/event-helper';
import { EventValidation } from '../../_validation/event-validation';
import { EventListService, EditEventService, DataService } from '../../_services';
import { AutoSelectService } from '../../_services/event/auto-select.service';
import { ProjectsComponent } from './projects/projects.component';
import { CustomersComponent } from './customers/customers.component';

@Component({
    selector: 'app-edit-event',
    templateUrl: './edit-event.component.html',
    styleUrls: ['./edit-event.component.scss']
})

export class EditEventComponent implements OnInit {
    @ViewChild('descriptionArea') descriptionElement: ElementRef;
    @ViewChild(ProjectsComponent) projects: ProjectsComponent;
    @ViewChild(CustomersComponent) customers: CustomersComponent;

    event: any = {};
    urlId: string;

    isDescriptionEmpty = false;

    eventTimeSpent: number = 0;
    currentTimeSpent: number = 0;
    currentDescription: string = '';
    currentCustomerName: string = '';
    currentProjectName: string = '';
    deploymentVersion: string = '';
    currentCustomerId: number = 0;
    currentProjectId: number = 0;

    displayHoursSpent = 0;
    displayMinutesSpent = 0;

    onDateTotalMinutes: number = 0;
    displayTotalHoursSpent: number = 0;
    displayTotalMinutesSpent: number = 0;

    previosUserEvent: any;
    source: string;
    currentYear: number;

    constructor(private router: Router, private activatedRoute: ActivatedRoute,
        private userService: UserService, private DatePipe: DatePipe,
        private eventListService: EventListService, private editEventService: EditEventService,
        private dataService: DataService, private eventValidation: EventValidation,
        private autoSelectService: AutoSelectService, private eventHelper: EventHelper) { }

    async ngOnInit() {
        if (localStorage.getItem('token') == null) {
            this.router.navigateByUrl('');
        }

        this.deploymentVersion = localStorage.getItem('deploymentVersion');
        this.event.Date = this.activatedRoute.snapshot.paramMap.get('date');
        this.urlId = (this.activatedRoute.snapshot.paramMap.get('id'));
        this.source = (this.activatedRoute.snapshot.paramMap.get('source'));

        await this.eventListService.editEvent(this.urlId).subscribe(
            (res: any) => {
                this.currentCustomerId = res.singleEvent.Project.Customer.Id;
                this.customers.populateList();
                this.event = res.singleEvent;
                this.eventTimeSpent = res.singleEvent.MinutesSpent;
                this.currentTimeSpent = res.singleEvent.MinutesSpent;
                this.currentDescription = res.singleEvent.UserComment;
                this.decodeDescription();
                this.currentProjectId = res.singleEvent.Project.Id;
            },
            err => console.error(err)
        );

        await this.editEventService.getTotalMinutes(this.event.Date).subscribe(
            (res: any) => {
                if (res.totalMinutes !== null) {
                    this.onDateTotalMinutes = res.totalMinutes;
                    this.formatTimeSpent();
                }
            },
            err => console.error(err)
        );
        this.currentYear = new Date().getFullYear();
    }

    loadProjects() {
        this.projects.populateList();
        this.currentCustomerName = this.event.Project.Customer.Name;
        this.currentProjectName = this.event.Project.Name;
    }

    selectTimeSpent(timeSpent: number) {
        this.currentTimeSpent = timeSpent;
        this.event.MinutesSpent = timeSpent;
        this.formatTimeSpent();
    }

    formatTimeSpent() {
        this.displayHoursSpent = Math.floor(this.currentTimeSpent / 60);
        this.displayMinutesSpent = Math.floor(this.currentTimeSpent % 60);

        this.displayTotalHoursSpent = Math.floor((this.onDateTotalMinutes - this.eventTimeSpent + this.currentTimeSpent) / 60);
        this.displayTotalMinutesSpent = Math.floor((this.onDateTotalMinutes - this.eventTimeSpent + this.currentTimeSpent) % 60);
    }

    changeDescription(descriptionValue) {
        this.currentDescription = descriptionValue;
        this.event.UserComment = descriptionValue;
    }

    selectCustomerId(customerValue) {
        this.currentCustomerId = customerValue;
        this.dataService.sendCustomerId(customerValue);
        this.autoSelectService.autoSelectDataForCustomer(customerValue, this.event.Date.substring(0, this.event.Date.length - 9)).subscribe(
            (res: any) => {
                if (res.previousEvent != null) {
                    this.currentProjectId = res.previousEvent.Project.Id;
                    this.selectProjectId(this.currentProjectId);
                }
                else {
                    this.currentProjectId = 0;
                }
                this.formatTimeSpent();
            }
        );
    }

    selectProjectId(projectValue) {
        this.currentProjectId = projectValue;
        this.event.Project.Id = projectValue;
    }

    selectRecentProject(data: any) {
        this.currentCustomerId = data.customerId;
        this.dataService.sendCustomerId(data.customerId);

        this.currentProjectId = data.projectId;
        this.event.Project.Id = data.projectId;
    }

    onSaveClick() {
        this.event.Customer = this.event.Project.Customer;
        const { errors, isValid } = this.eventValidation.validateEvent(this.event);
        let eventDate = this.event.Date.substring(0, this.event.Date.length - 9);

        if (!isValid) {
            this.eventHelper.displayToasterErrorMessages(errors);
        } else {
            const event = {
                Id: this.event.Id,
                Project: { Id: this.currentProjectId },
                User: { Id: this.event.User.Id },
                MinutesSpent: this.currentTimeSpent,
                MinutesEstimate: this.event.MinutesEstimate,
                UserComment: this.currentDescription,
                Date: this.event.Date,
                IsDeleted: this.event.IsDeleted
            };
            this.eventHelper.replaceAndChar(event);

            this.editEventService.updateEvent(event).subscribe(
                (res: any) => {
                    if (res)
                        this.dataService.sendDatetime(res.eventList);
                    this.router.navigateByUrl(`/event-list/${eventDate}`);
                },
                err => console.error(err)
            );
        }
    }

    onCancelClick() {
        let eventDate = this.event.Date.substring(0, this.event.Date.length - 9);
        if (this.source == "home") {
            this.router.navigateByUrl('/home');
        } else {
            this.router.navigateByUrl(`/event-list/${eventDate}`);
        }
    }

    checkFocus() {
        if (window.innerWidth > 768) {
            this.invokeFocusOnTextArea();
        };
    }

    public invokeFocusOnTextArea() {
        this.descriptionElement.nativeElement.focus();
    }

    previousDateArrowClick() {
        let newDate = new Date(this.event.Date);
        newDate.setDate(newDate.getDate() - 1);
        this.event.Date = this.DatePipe.transform(newDate, 'yyyy-MM-dd');

        this.eventListService.populateEventList(this.event.Date).subscribe(
            (res: any) => {
                this.dataService.sendDatetime(res.eventList);
                this.router.navigate([`event-list/${this.event.Date}`]);
            });
    }

    nextDateArrowClick() {
        let newDate = new Date(this.event.Date);
        newDate.setDate(newDate.getDate() + 1);
        this.event.Date = this.DatePipe.transform(newDate, 'yyyy-MM-dd');

        this.eventListService.populateEventList(this.event.Date).subscribe(
            (res: any) => {
                this.dataService.sendDatetime(res.eventList);
                this.router.navigate([`event-list/${this.event.Date}`]);
            });
    }

    onLogout() {
        localStorage.removeItem('token');
        sessionStorage.removeItem('month');
        sessionStorage.removeItem('year');
        this.router.navigate(['/user/login']);
    }

    decodeDescription() {
        this.currentDescription = this.currentDescription.replace(/\&amp;/g, "&");
    }
}
